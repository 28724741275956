<template>
	<dir>
		<el-dialog :visible.sync="open" width="900px" @close="commitOnClose">
			<el-tabs v-model="activeName" @tab-click="handleClick">
				<el-tab-pane label="基本信息" name="1">
					<el-form ref="form" :model="baseInfo" label-width="0">
						<table class="base-table">
							<tr>
								<th>联系人手机</th>
								<td>
									<template>
										{{
											hideContactFn(
												merchantInfo.contactPhone
											)
										}}
									</template>
								</td>
								<th>联系人</th>
								<td>{{ merchantInfo.contactName }}</td>
								<th>注册时间</th>
								<td>{{ merchantInfo.createTime }}</td>
							</tr>
							<tr>
								<th>商户编号</th>
								<td>{{ merchantInfo.merchantNo }}</td>
								<th>商户简称</th>
								<td>{{ merchantInfo.merSubName }}</td>
								<th>商户全称</th>
								<td>{{ merchantInfo.merFullName }}</td>
							</tr>
							<tr>
								<th>商户状态</th>
								<td>
									{{
										merchantInfo.status
											| newMerchantStatusFM
									}}
								</td>
								<th></th>
								<td></td>
								<th></th>
								<td></td>
							</tr>
							<tr>
								<th>所属代理商</th>
								<td>{{ merchantInfo.agentNo }}</td>
								<th>所属招商中心</th>
								<td>{{ merchantInfo.orgNo }}</td>
								<th>所属行业（mcc）</th>
								<td>{{ merchantInfo.subMcc }}</td>
							</tr>
							<tr>
								<th></th>
								<td></td>
								<th>所属地区</th>
								<td>
									{{ merchantInfo.provinceZh }}-{{
										merchantInfo.cityZh
									}}
								</td>
								<th>经营地址</th>
								<td>
									<template>{{
										merchantInfo.address
									}}</template>
								</td>
							</tr>
							<tr>
								<th>审核通过时间</th>
								<td>{{ merchantInfo.checkPassTime }}</td>
								<th></th>
								<td></td>
								<th></th>
								<td></td>
							</tr>
						</table>
					</el-form>
					<!-- <el-row>
                    <el-col :span="1.5">
                        <el-button type="primary"
                                @click="handleUpdate" :loading="loading" v-permission="'MERCHANT_INFO_UPDATE'">
                            {{baseUpdate?'确定修改':'修改'}}
                        </el-button>
                        <el-button @click="handleClose">取消</el-button>
                    </el-col>
                </el-row> -->
				</el-tab-pane>
				<el-tab-pane label="企业信息" name="2">
					<el-form ref="bsForm" :model="businessInfo" label-width="0">
						<table class="base-table">
							<tr>
								<th>营业执照名称</th>
								<td>{{ merchantInfo.businessLicenseName }}</td>
								<th>营业执照有效期</th>
								<td>
									<template
										>{{
											merchantInfo.businessLicenseStartDate
										}}-{{
											merchantInfo.businessLicenseEndDate
										}}</template
									>
								</td>
							</tr>
							<tr>
								<th>法人姓名</th>
								<td>
									<template>{{
										merchantInfo.legalPerson
									}}</template>
								</td>
								<th></th>
								<td></td>
							</tr>
							<!-- <tr>
                        <th>身份证号</th>
                        <td>
                            <template>{{merchantInfo.certificateNo}}</template>
                        </td>
                        <th>法人身份证有效期</th>
                        <td>
                            <template>{{merchantInfo.certificateStartDate}}-{{merchantInfo.certificateEndDate}}</template>
                        </td>
                    </tr> -->
						</table>
					</el-form>
				</el-tab-pane>
				<el-tab-pane label="结算信息" name="3">
					<table class="base-table">
						<tr>
							<th>结算方式</th>
							<td>
								{{
									merchantInfo.accountType | newAccountTypeFM
								}}
							</td>
							<th></th>
							<td></td>
						</tr>
						<tr>
							<th>银行账号</th>
							<td>{{ merchantInfo.accountNo }}</td>
							<th>结算账户名</th>
							<td>{{ merchantInfo.accountName }}</td>
						</tr>
						<tr>
							<th>开户行名称</th>
							<td>{{ merchantInfo.bankName }}</td>
							<th>所属省市</th>
							<td>
								{{ merchantInfo.accountProvinceZh }}-{{
									merchantInfo.accountCityZh
								}}
							</td>
						</tr>
						<tr>
							<th>银行卡预留手机号</th>
							<td>
								{{
									hideContactFn(merchantInfo.accountMobileNo)
								}}
							</td>
							<th>结算人身份证号</th>
							<td>
								{{ maskIDNumber(merchantInfo.settleIdCard) }}
							</td>
						</tr>
					</table>
				</el-tab-pane>
				<el-tab-pane label="费率限额" name="4">
					<table class="base-table">
						<tr>
							<th>支付类型</th>
							<th>卡类型</th>
							<th>T0费率(%)</th>
							<th>T1费率(%)</th>
						</tr>
						<tr v-for="item in merRateList" :key="item.id">
							<td>{{ item.payTypeCode | payType }}</td>
							<td>{{ item.cardType | formateCardType }}</td>
							<td>{{ item.t0Rate }}</td>
							<td>{{ item.t1Rate }}</td>
						</tr>
					</table>
				</el-tab-pane>
			</el-tabs>
		</el-dialog>
	</dir>
</template>
<script>
import { MerchantApi } from "@/api";
export default {
	data() {
		var checkDate = (rule, value, callback) => {
			if (!value && !this.foreverCheck) {
				return callback(new Error("有效期不能为空"));
			} else {
				callback();
			}
		};
		var checkDate2 = (rule, value, callback) => {
			if (!value && !this.foreverCheck2) {
				return callback(new Error("有效期不能为空"));
			} else {
				callback();
			}
		};
		return {
			activeName: "1",
			baseInfo: "",
			businessInfo: "",
			merFileItemList: "",
			merRateList: "",
			baseUpdate: false,
			businessUpdate: false,
			open: false,
			foreverCheck: false,
			foreverCheck2: false,
			merchantInfo: {},
			state: ""
		};
	},
	props: {
		data: {
			type: [Array, Object, String]
		},
		onFresh: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		onFresh: function(value) {
			this.open = value;
		},
		data: function() {
			this.merchantInfo = this.data.merchantInfoDo;
			this.merRateList = this.data.merRateList;
		}
	},
	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
		commitOnClose() {
			this.$emit("on-close");
		},
		handleClose() {
			this.baseUpdate = false;
		},
		handleUpdate() {
			if (this.baseUpdate) {
				this.$refs.form.validate(valid => {
					if (valid) {
						this.loading = true;
						let params = {
							address: this.baseInfo.address,
							merSubName: this.baseInfo.merSubName,
							mobileNo: this.baseInfo.mobileNo
						};
						MerchantApi.updateMerBaseInfo(
							this.baseInfo.merchantNo,
							params
						).then(res => {
							if (res.success) {
								this.Message.success("修改成功");
								this.baseUpdate = false;
							}
							this.loading = false;
						});
					} else {
						return false;
					}
				});
			} else {
				this.baseUpdate = true;
			}
		},
		handleBsClose() {
			this.businessUpdate = false;
		},
		handleBsUpdate() {
			if (this.businessUpdate) {
				this.$refs.bsForm.validate(valid => {
					if (valid) {
						this.bsLoading = true;
						let params = {
							legalIdCard: this.businessInfo.legalIdCard,
							legalName: this.businessInfo.legalName
						};
						MerchantApi.updateMerBusinessInfo(
							this.baseInfo.merchantNo,
							params
						).then(res => {
							if (res.success) {
								this.Message.success("修改成功");
								this.businessUpdate = false;
							}
							this.bsLoading = false;
						});
					} else {
						return false;
					}
				});
			} else {
				this.businessUpdate = true;
			}
		},
		// 身份证脱敏
		maskIDNumber(IDCode) {
			if (typeof IDCode === "string") {
				return IDCode.replace(/^(\d{6})\d+(\d{4})$/, "$1********$2");
			} else {
				return " ";
			}
		},
		// 手机号脱敏
		hideContactFn(contact) {
			if (typeof contact === "string") {
				return contact.replace(/(\d{3})(\d{4})(\d{4})/, "$1****$3");
			} else {
				return " ";
			}
		}
	}
};
</script>
